.card.--has-max {
  display: block;
  max-width: 434px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px;

  font-size: rem(16px);
  line-height: rem(21px);

  color: var(--color-dark-shade);

  @media (min-width: $size-desktop) {
    max-width: 375px;
    padding: 0;
  }
}
