:root {
  // Primary
  --color-primary: #0099ff;
  --color-primary-rgb: 0, 153, 255;
  // Secondary
  --color-secondary: #99cc33;
  --color-secondary-rgb: 153, 204, 51;
  --color-secondary-shade: #25d366;
  --color-secondary-shade-rgb: 37, 211, 102;

  // Light
  --color-light: #f3f5f7;
  --color-light-rgb: 243, 245, 247;
  --color-light-alt: #dce2e9;
  --color-light-alt-rgb: 220, 226, 233;
  --color-light-tint: #ffffff;
  --color-light-tint-rgb: 255, 255, 255;
  --color-light-shade: #c5c5c5;
  --color-light-shade-rgb: 197, 197, 197;

  // Dark
  --color-dark: #6e7c91;
  --color-dark-rgb: 110, 124, 145;
  --color-dark-tint: #8a9eb5;
  --color-dark-tint-rgb: 138, 158, 181;
  --color-dark-shade: #39424f;
  --color-dark-shade-rgb: 57, 66, 79;

  // Auxiliary
  --color-auxiliary: #fe8719;
  --color-auxiliary-rgb: 254, 135, 25;

  // Success
  --color-success: #03b304;
  --color-success-rgb: 3, 179, 4;

  // Danger
  --color-danger: #972a1d;
  --color-danger-rgb: 151, 42, 29;
  --color-danger-light: #fef3f6;
  --color-danger-light-rgb: 254, 243, 246;
}
