@import "icomoon";

:root {
  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Roboto", serif;
}

body {
  font-family: var(--font-primary);
  color: var(--color-dark);
}
