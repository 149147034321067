.l-loading-dialog {
  padding: 20px;

  .mat-dialog-container {
    background-color: var(--color-light-tint);
    text-align: center;
    padding: 30px 44px;
    border-radius: 8px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  }
}
