@use "src/styles/base/mixins/rem";

.otp-input {
  padding: 0;
  margin: 0 !important;
  font-weight: 400;

  font-size: rem.rem(16px) !important;
  line-height: rem.rem(21px) !important;

  color: var(--color-dark);
  border-color: #d6d6d6 !important;

  width: 48px !important;
  height: 48px !important;

  outline: none;
}

.ng-otp-input-wrapper {
  display: flex;
  justify-content: space-between;

  max-width: 216px;
  margin: auto;
}
