@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon/icomoon.eot?f5jjla");
  src: url("/assets/fonts/icomoon/icomoon.eot?f5jjla#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon/icomoon.ttf?f5jjla") format("truetype"),
    url("/assets/fonts/icomoon/icomoon.woff?f5jjla") format("woff"),
    url("/assets/fonts/icomoon/icomoon.svg?f5jjla#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-card-locked:before {
  content: "\e90b";
}
.icon-pix:before {
  content: "\e90a";
}
.icon-sms:before {
  content: "\e909";
}
.icon-vivo:before {
  content: "\e908";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-whatsapp:before {
  content: "\e906";
}
.icon-alert-circle:before {
  content: "\e900";
}
.icon-bar-code:before {
  content: "\e901";
}
.icon-card:before {
  content: "\e902";
}
.icon-check-circle:before {
  content: "\e903";
}
.icon-clock:before {
  content: "\e904";
}
.icon-lock:before {
  content: "\e905";
}
