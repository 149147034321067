@use "../../styles/base/breakpoints";
@use "../../styles/base/mixins/rem";

@mixin container {
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);

  @media (min-width: breakpoints.$size-desktop) {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  }
}

.m-container {
  @include container;
  width: 100%;
  background-color: var(--color-light-tint);
  padding: 30px;

  &__footer-availability {
    display: flex;
    align-items: center;
    border-top: 1px solid rgb(var(--color-light-shade-rgb), 0.6);
    padding: 20px;

    i {
      display: block;
      font-size: rem.rem(32px);
      margin-right: 12px;
      margin-left: -6px;
      color: var(--color-auxiliary);
      align-self: flex-start;
    }

    strong {
      font-weight: 500;
    }
  }

  &.--ordered-list {
    padding: 23px 30px 30px 35px;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 0;

    @media (min-width: breakpoints.$size-desktop) {
      @include container;
    }
  }
}
