/* You can add global styles to this file, and also import other style files */

@import "./styles/vendor.scss";

@import "./styles/base";

@import "./styles/layouts/loading-dialog";
@import "./styles/layouts/success";

@import "./styles/modules";

@import "./styles/states";

