.l-success {
  @media (min-width: $size-desktop) {
    display: flex;
  }

  app-plan {
    @media (min-width: $size-desktop) {
      display: flex;
      order: 2;
    }
  }

  app-next-steps {
    @media (min-width: $size-desktop) {
      flex: 0 0 592px;
    }
  }
}
