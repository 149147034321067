.m-button-primary {
  display: block;
  width: 100%;
  padding: 13px 13px 14px;
  border-radius: 6px;
  background-color: var(--color-primary);
  color: var(--color-light-tint);
  font-size: rem(16px);
  font-weight: 500;
  transition: filter 0.25s ease, opacity 0.25s ease;
  text-align: center;

  &:hover {
    filter: brightness(1.2);
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.--clear {
    background-color: transparent;
    color: var(--color-primary);

    padding: 0;

    &[disabled] {
      opacity: 1;
    }
  }
}
